import { Button, Flex, Input, Tooltip } from "antd";
import React from "react";
import { MapsCardProps } from "src/types/component-types/gudauriPageProps";

import classes from "./MapsCard.module.css";
import { LuUploadCloud } from "react-icons/lu";

const MapsCard: React.FC<MapsCardProps> = ({
  loading,
  link,
  image,
  isEdit,
  mediaEdit,
  handleFieldChange,
  handleEdit,
  handleConfirm,
  handleCancel,
}) => {
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFieldChange("image", e.target.files[0]);
    }
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFieldChange("link", e.target.value);
  };

  return (
    <Flex align="flex-start" vertical gap={24}>
      <Flex vertical gap={12}>
        <Flex
          justify="center"
          align="center"
          className={`${classes.imageWrapper} ${isEdit && classes.edit}`}
        >
          {isEdit ? (
            <>
              <Flex vertical gap={4} align="center">
                <LuUploadCloud />
                <h2>Upload Image</h2>
              </Flex>
              <input
                type="file"
                onChange={handleImageChange}
                className={classes.imgInp}
              />
            </>
          ) : (
            <Tooltip title={link} className={classes.tooltip}>
              <img src={image.url} alt="map" className={classes.img} />
            </Tooltip>
          )}
        </Flex>
        {isEdit && (
          <Flex align="center" gap={4}>
            <p>Link: </p>
            <Input value={mediaEdit?.link} onChange={handleLinkChange} />
          </Flex>
        )}
      </Flex>

      <Flex align="center" gap={8}>
        <Button onClick={isEdit ? handleCancel : handleEdit} loading={loading}>
          {isEdit ? "Cancel" : "Edit"}
        </Button>
        {isEdit && (
          <Button onClick={handleConfirm} type="primary" loading={loading}>
            Confirm
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default MapsCard;
