import { DescribedContentT, MapsT } from "../../../../../types/common-types";
import { messageResT } from "../../../../../api/responses";
import MediaCard from "../../../../../components/MediaCard/MediaCard";
import { useApiRequest } from "../../../../../hooks/useApiRequests";
import useMessages from "../../../../../hooks/useMessages";
import { cloudinaryUpload } from "../../../../../lib/cloudinaryUpload";
import { HowToGetThereProps } from "../../../../../types/component-types/gudauriPageProps";
import { Flex } from "antd";
import React, { useState } from "react";
import {
  updateGudauriHowToGetSection,
  updateGudauriMapsImage,
} from "../../../../../api/services/aboutPageServices/gudauriServices";
import MediaEditModal from "../../../../../components/MediaEditModal/MediaEditModal";
import MapsCard from "./components/MapsCard";
import BannerResolutionWarning from "src/components/BannerResolutionWarning/BannerResolutionWarning";

const HowToGetSection: React.FC<HowToGetThereProps> = ({ data, getData }) => {
  const [editSection, setEditSection] = useState<DescribedContentT>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mediaEdit, setMediaEdit] = useState<MapsT>();

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
    setEditSection(data);
  };

  const handleUpdateSection = () => {
    try {
      if (editSection) {
        setLoading(true);
        makeRequest(
          updateGudauriHowToGetSection(
            {
              ...editSection,
            },
            editSection._id
          ),
          (e) => {
            success(e.message);
            setIsEditModalOpen(false);
            getData();
            setLoading(false);
          }
        );
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }

    setEditSection(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as DescribedContentT)
    );
    setLoading(false);
  };

  const handleMapsFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (fieldName === "image" && value) {
      image = await cloudinaryUpload(value);
    }

    setMediaEdit(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MapsT)
    );
    setLoading(false);
  };

  const handleMapsEdit = (id: string) => {
    const filteredData = data.maps.find((e) => e._id === id);
    setMediaEdit(filteredData);
  };

  const handleCancel = () => {
    setMediaEdit(undefined);
  };

  const handleMapsEditConfirm = () => {
    try {
      if (mediaEdit) {
        setLoading(true);
        makeRequest(updateGudauriMapsImage(mediaEdit, mediaEdit._id), (e) => {
          getData();
          success(e.message);
          setMediaEdit(undefined);
          setLoading(false);
        });
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>How to get there section</h2>

          <MediaCard data={data} onEdit={handleEditModalOpen} />

          <MediaEditModal
            isMediaEditModalOpen={isEditModalOpen}
            handleOk={handleUpdateSection}
            handleCancel={() => setIsEditModalOpen(false)}
            handleFieldChange={handleFieldChange}
            mediaDetails={editSection}
            isLoading={loading}
          />

          <BannerResolutionWarning width="323" height="320" />

          <Flex justify="space-between" gap={12} wrap>
            {data.maps.map((e) => (
              <MapsCard
                handleCancel={handleCancel}
                handleConfirm={handleMapsEditConfirm}
                isEdit={mediaEdit ? mediaEdit._id === e._id : false}
                mediaEdit={mediaEdit}
                loading={loading}
                handleFieldChange={handleMapsFieldChange}
                handleEdit={() => handleMapsEdit(e._id)}
                {...e}
              />
            ))}
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default HowToGetSection;
