import { Alert, Button, Flex, Tooltip } from "antd";
import classes from "./mediaCard.module.css";
import { ReactNode } from "react";

type MediaCardProps = {
  resolutionWarning?: string;
  data: {
    _id: string;
    image?: {
      public_id: string;
      url: string;
    };
    title?: string;
    subtitle?: string | ReactNode;
    description?: string;
    locationTitle?: string;
    warningTitle?: string;
    warning?: string;
    location?: string;
    locationLink?: string;
    email?: string;
    workDays?: number;
    phoneNumber?: string;
    gudauriToTbilisiTransferDesc?: string;
    gudauriToTbilisiAirportDesc?: string;
    tbilisiAirportToGudauriDesc?: string;
    tbilisiFreedomSquareToGudauriDesc?: string;
    gudauriToKazbegiTourDesc?: string;
    gudauriToGergetiExcursionDesc?: string;
    gudauriToKhadaExplorationDesc?: string;
    transferFromTbilisiToKazbegiDesc?: string;
    fullDayTourTbilisiToKazbegiDesc?: string;
  };
  onEdit: (id: string) => void;
  onDelete?: (id: string) => void;
};

const MediaCard: React.FC<MediaCardProps> = ({
  data,
  onEdit,
  onDelete,
  resolutionWarning,
}) => {
  const {
    _id,
    image,
    title,
    subtitle,
    description,
    warningTitle,
    warning,
    locationTitle,
    location,
    locationLink,
    email,
    workDays,
    phoneNumber,
    gudauriToTbilisiTransferDesc,
    gudauriToTbilisiAirportDesc,
    tbilisiAirportToGudauriDesc,
    tbilisiFreedomSquareToGudauriDesc,
    gudauriToKazbegiTourDesc,
    gudauriToGergetiExcursionDesc,
    gudauriToKhadaExplorationDesc,
    transferFromTbilisiToKazbegiDesc,
    fullDayTourTbilisiToKazbegiDesc,
  } = data;
  return (
    <Flex vertical gap={12} className={classes.mediaCard}>
      <div className={classes.imgContainer}>
        {image ? (
          <img src={image?.url} alt="img" className={classes.img} />
        ) : null}
      </div>

      {resolutionWarning ? (
        <Alert
          message="Warning: Invalid Image Resolution on Banner"
          description={resolutionWarning}
          type="warning"
          showIcon
        />
      ) : null}

      <Flex
        className={classes.textContent}
        vertical
        justify="space-between"
        gap={24}
        align="flex-start"
      >
        <Flex vertical gap={8}>
          {title ? (
            <h4>
              <span className={classes.textIndicator}>Title: </span> {title}
            </h4>
          ) : null}
          {subtitle && (
            <h4>
              <span className={classes.textIndicator}>Subtitle: </span>
              {subtitle}
            </h4>
          )}

          {tbilisiAirportToGudauriDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Tbilisi Airport to Gudauri Description:{" "}
              </span>
              {tbilisiAirportToGudauriDesc}
            </h4>
          )}

          {tbilisiFreedomSquareToGudauriDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Tbilisi Freedom Square to Gudauri Description:{" "}
              </span>
              {tbilisiFreedomSquareToGudauriDesc}
            </h4>
          )}

          {transferFromTbilisiToKazbegiDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Transfer from Tbilisi to Kazbegi Description:{" "}
              </span>
              {transferFromTbilisiToKazbegiDesc}
            </h4>
          )}

          {gudauriToTbilisiTransferDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Gudauri to Tbilisi Transfer Description:{" "}
              </span>
              {gudauriToTbilisiTransferDesc}
            </h4>
          )}

          {gudauriToTbilisiAirportDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Gudauri to Tbilisi Airport Description:{" "}
              </span>
              {gudauriToTbilisiAirportDesc}
            </h4>
          )}

          {gudauriToKazbegiTourDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Gudauri to Kazbegi Tour Description:{" "}
              </span>
              {gudauriToKazbegiTourDesc}
            </h4>
          )}

          {gudauriToGergetiExcursionDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Gudauri to Gergeti Excursion Description:{" "}
              </span>
              {gudauriToGergetiExcursionDesc}
            </h4>
          )}

          {gudauriToKhadaExplorationDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Gudauri to Khada Exploration Description:{" "}
              </span>
              {gudauriToKhadaExplorationDesc}
            </h4>
          )}

          {fullDayTourTbilisiToKazbegiDesc && (
            <h4>
              <span className={classes.textIndicator}>
                Full Day Tour Tbilisi to Kazbegi Description:{" "}
              </span>
              {fullDayTourTbilisiToKazbegiDesc}
            </h4>
          )}

          {description && (
            <h4>
              <span className={classes.textIndicator}>Description: </span>
              {description}
            </h4>
          )}

          {warningTitle && (
            <h4>
              <span className={classes.textIndicator}>Warning Title: </span>
              {warningTitle}
            </h4>
          )}
          {warning && (
            <h4>
              <span className={classes.textIndicator}>Warning: </span>
              {warning}
            </h4>
          )}
          {email && (
            <h4>
              <span className={classes.textIndicator}>Email: </span>
              {email}
            </h4>
          )}

          {workDays && (
            <h4>
              <span className={classes.textIndicator}>Work Days: </span>
              {workDays}
            </h4>
          )}

          {phoneNumber && (
            <h4>
              <span className={classes.textIndicator}>Phone Number: </span>
              {phoneNumber}
            </h4>
          )}

          {locationTitle && (
            <h4>
              <span className={classes.textIndicator}>Location Title: </span>
              {locationTitle}
            </h4>
          )}

          {location &&
            (locationLink ? (
              <Tooltip title={locationLink}>
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {location}
                </span>
              </Tooltip>
            ) : (
              <h4>
                <span className={classes.textIndicator}>Location: </span>
                {location}
              </h4>
            ))}
        </Flex>
        <Flex justify="flex-end" gap={4} style={{ width: "100%" }}>
          <Button className={classes.btn} onClick={() => onEdit(_id)}>
            Edit
          </Button>
          {onDelete ? (
            <Button
              type="primary"
              danger
              className={classes.btn}
              onClick={() => onDelete(_id)}
            >
              Delete
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MediaCard;
