import { useState } from "react";
import { Flex } from "antd";
import MediaCard from "../../../MediaCard/MediaCard";
import MediaEditModal from "../../../MediaEditModal/MediaEditModal";
import { MediaDataT } from "../../../../types/mediaType";
import { WelcomeFirstProps } from "../../../../types/component-types/homepageProps";
import { messageResT } from "../../../../api/responses";
import useMessages from "../../../../hooks/useMessages";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { updateHomepageWelcomeFirstSection } from "../../../../api/services/homePageServices";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";
import BannerResolutionWarning from "src/components/BannerResolutionWarning/BannerResolutionWarning";

const WelcomeFirstSection: React.FC<WelcomeFirstProps> = ({
  welcomeFirstData,
  getData,
}) => {
  const { _id, title, subtitle, image } = welcomeFirstData;
  const [editWelcomeFirstDetail, setEditWelcomeFirstDetail] =
    useState<MediaDataT>();
  const [isEditWelcomeFirstModalOpen, setIsEditWelcomeFirstModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, makeUpdateRequest] = useApiRequest<messageResT>();

  const { error, success, contextHolder } = useMessages();

  const showEditWelcomeFirstModal = () => {
    setIsEditWelcomeFirstModalOpen(true);
    setEditWelcomeFirstDetail(welcomeFirstData);
  };

  const handleOkEditWelcomeFirst = () => {
    if (editWelcomeFirstDetail) {
      try {
        makeUpdateRequest(
          updateHomepageWelcomeFirstSection(
            {
              ...editWelcomeFirstDetail,
            },
            editWelcomeFirstDetail._id
          ),
          (e) => {
            setIsEditWelcomeFirstModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        error("Something went wrong");
      }
    }
  };

  const handleCancelEditWelcomeFirst = () => {
    setIsEditWelcomeFirstModalOpen(false);
  };

  const handleWelcomeFirstFieldChange = async (
    fieldName: string,
    value: string
  ) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setEditWelcomeFirstDetail(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  const paragraphs = subtitle.split("\n");
  const paragraphsWithBreaks = paragraphs.map((paragraph, index) => (
    <div key={index}>
      <h4>{paragraph}</h4>
      <br />
    </div>
  ));

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>Welcome to Ride Gudauri</h2>

          <BannerResolutionWarning width="488" height="418" />

          <MediaCard
            data={{
              _id: _id,
              image: image,
              title: title,
              subtitle: paragraphsWithBreaks,
            }}
            onEdit={showEditWelcomeFirstModal}
          />
        </Flex>

        <MediaEditModal
          isMediaEditModalOpen={isEditWelcomeFirstModalOpen}
          handleOk={handleOkEditWelcomeFirst}
          handleCancel={handleCancelEditWelcomeFirst}
          handleFieldChange={handleWelcomeFirstFieldChange}
          mediaDetails={editWelcomeFirstDetail}
          isLoading={loading}
          useTextAreaForSubtitleEdit
        />
      </section>
    </>
  );
};

export default WelcomeFirstSection;
