import { ColumnsType } from "antd/es/table";
import { OtherActivitiesT } from "../OtherActivities";

export const otherActivitiesColumns: ColumnsType<OtherActivitiesT> = [
  {
    title: "Activity Type",
    dataIndex: "type",
    key: "type",
    fixed: "left",
  },
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
    // fixed: "left",
  },
  {
    title: "Phone Number",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "Amount of Participants",
    dataIndex: "participants",
    key: "participants",
  },
  {
    title: "Selector",
    dataIndex: "selector",
    key: "selector",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Additional Details",
    dataIndex: "additionalDetails",
    key: "additionalDetails",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    fixed: "right",
    width: 100,
  },
];
