import { useState } from "react";
import useMessages from "../../../../hooks/useMessages";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { updateRentalShopSectionSkiSchool } from "../../../../api/services/skiSchoolServices";
import MediaCard from "../../../../components/MediaCard/MediaCard";
import MediaEditModal from "../../../../components/MediaEditModal/MediaEditModal";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";
import { RentalShopProps } from "../../../../types/component-types/skiSchoolPageProps";
import { MediaDataT } from "../../../../types/mediaType";
import { Flex } from "antd";
import BannerResolutionWarning from "src/components/BannerResolutionWarning/BannerResolutionWarning";

const RentalShop: React.FC<RentalShopProps> = ({ rentalShopData, getData }) => {
  const { _id, title, subtitle, image } = rentalShopData;
  const [editAboutDetail, setEditAboutDetail] =
    useState<MediaDataT>(rentalShopData);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, makeUpdateRequest] = useApiRequest();
  const { error, success, contextHolder } = useMessages();

  const showEditModal = () => {
    setIsEditModalOpen(true);
    setEditAboutDetail(rentalShopData);
  };

  const handleOkEdit = () => {
    if (editAboutDetail) {
      try {
        makeUpdateRequest(
          updateRentalShopSectionSkiSchool(editAboutDetail._id, {
            title: editAboutDetail.title,
            subtitle: editAboutDetail.subtitle,
            image: editAboutDetail.image,
          }),
          (e: any) => {
            setIsEditModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        error("Something went wrong");
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = async (fieldName: string, value: string) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setEditAboutDetail((prev) => ({
      ...prev,
      [fieldName]: fieldName === "image" ? image : value,
    }));
    setLoading(false);
  };

  const paragraphs = subtitle.split("\n");
  const paragraphsWithBreaks = paragraphs.map((paragraph) => (
    <>
      <h4>{paragraph}</h4>
      <br />
    </>
  ));

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>Rental shop</h2>

          <BannerResolutionWarning width="488" height="344" />

          <MediaCard
            data={{
              _id: _id,
              image: image,
              title: title,
              subtitle: paragraphsWithBreaks,
            }}
            onEdit={showEditModal}
          />
        </Flex>

        <MediaEditModal
          isMediaEditModalOpen={isEditModalOpen}
          handleOk={handleOkEdit}
          handleCancel={handleCancelEdit}
          handleFieldChange={handleFieldChange}
          mediaDetails={editAboutDetail}
          isLoading={loading}
          useTextAreaForSubtitleEdit
        />
      </section>
    </>
  );
};

export default RentalShop;
