import React, { useEffect, useRef, useState } from "react";
import { ColumnType, FilterValue } from "antd/es/table/interface";
import { Button, Flex, InputRef, Space, Table, TableProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { otherActivitiesColumns } from "./TableColumns/TableColumns";
import { ColumnSearch } from "../ColumnSearch";
import { TableWrapper } from "../TableWrapper";
import { ActionsOnEditModal } from "../ActionsOnEditModal";
import { successEditModal } from "../SuccessEditModal";
import { successDeleteModal } from "../SuccessDeleteModal";
import { deleteModal } from "../DeleteModal";
import { EditModal } from "./EditModal";
import { ActivitiesSkeleton } from "../ActivitiesSkeleton";
import classes from "./otherActivities.module.css";
import { CurrencyT } from "src/types";

export type OtherActivitiesT = {
  _id: string;
  type: string;
  fullName: string;
  number: string;
  email: string;
  participants: string;
  date: string;
  time: string;
  selector: string;
  additionalDetails: string;
  currency: CurrencyT;
  // __v: number;
};

type DataIndex = keyof OtherActivitiesT;

const OtherActivities = () => {
  const [otherActivitiesInfo, setOtherActivitiesInfo] = useState<
    OtherActivitiesT[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // edit modal related
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editOrder, setEditOrder] = useState({
    _id: "",
    type: "",
    fullName: "",
    number: "",
    email: "",
    participants: "",
    date: "",
    time: "",
    selector: "",
    additionalDetails: "",
    currency: {
      usd: 0,
      gel: 0,
    },
  });

  const showModal = (id: string) => {
    const editableOrder = otherActivitiesInfo.find(
      (order: any) => order._id === id
    );
    setIsEditModalOpen(true);
    editableOrder !== undefined && setEditOrder(editableOrder);
  };

  const handleOk = () => {
    setIsEditModalOpen(false);
    handleEditOrder(editOrder._id);
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    if (fieldName === "currency_usd") {
      setEditOrder((prevEditOrder) => ({
        ...prevEditOrder,
        currency: {
          ...prevEditOrder.currency,
          usd: Number(value),
        },
      }));
    } else if (fieldName === "currency_gel") {
      setEditOrder((prevEditOrder) => ({
        ...prevEditOrder,
        currency: {
          ...prevEditOrder.currency,
          gel: Number(value),
        },
      }));
    } else {
      setEditOrder((prevEditOrder) => ({
        ...prevEditOrder,
        [fieldName]: value,
      }));
    }
  };

  // column search related
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange: TableProps<OtherActivitiesT>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<OtherActivitiesT> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <ColumnSearch
        ref={searchInput}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() =>
          handleSearch(selectedKeys as string[], confirm, dataIndex)
        }
        onSearchClick={() =>
          handleSearch(selectedKeys as string[], confirm, dataIndex)
        }
        onResetClick={() => {
          clearFilters && handleReset(clearFilters);
          handleSearch(selectedKeys as string[], confirm, dataIndex);
        }}
      />
    ),

    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{ fontSize: 14, color: filtered ? "#1677ff" : "#fff" }}
      />
    ),

    onFilter: (value: boolean | React.Key, record: OtherActivitiesT) => {
      const dataIndexValue = record[dataIndex]?.toString();

      if (dataIndexValue === undefined) {
        return false;
      }

      const filterValue = value as string;
      return dataIndexValue.toLowerCase().includes(filterValue.toLowerCase());
    },

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = otherActivitiesColumns.map(
    (column: ColumnType<OtherActivitiesT>) => {
      const columnDataIndex = column.dataIndex as keyof OtherActivitiesT;
      const columnFilteredValue = filteredInfo[columnDataIndex] || null;

      if (column.dataIndex === "currency") {
        return {
          ...column,
          render: (record: { usd: number; gel: number }) => (
            <Flex vertical gap={4}>
              <span> USD: {record.usd}$</span>
              <span> GEL: {record.gel}₾</span>
            </Flex>
          ),
        };
      }

      return {
        ...column,
        ...getColumnSearchProps(columnDataIndex),
        filteredValue: columnFilteredValue,
      };
    }
  );

  const fetchData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/otheractivities`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data: OtherActivitiesT[]) => {
        setOtherActivitiesInfo(data.reverse());
        setLoading(false);
      })
      .catch((error: Error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <ActivitiesSkeleton />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleEditOrder = (id: string) => {
    setLoading(true);
    const updatedData = { ...editOrder };

    fetch(`${process.env.REACT_APP_API_URL}/otheractivities/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to edit order");
        }
        successEditModal();
        return response.json();
      })
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const handleDeleteOrder = (id: string) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/otheractivities/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete order");
        }
        successDeleteModal();
        fetchData();
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  const showDeleteConfirm = (id: string) => {
    deleteModal(() => handleDeleteOrder(id));
  };

  const tableData: OtherActivitiesT[] = otherActivitiesInfo.map((item) => ({
    key: item._id,
    _id: item._id,
    type: item.type,
    fullName: item.fullName,
    number: item.number,
    email: item.email,
    participants: item.participants,
    date: item.date.split("T")[0],
    time: item.time,
    selector: item.selector,
    additionalDetails: item.additionalDetails,
    currency: item.currency,
    actions: (
      <ActionsOnEditModal
        onEditClick={() => showModal(item._id)}
        onDeleteClick={() => showDeleteConfirm(item._id)}
      />
    ),
  }));

  const handleResetAll = () => {
    setFilteredInfo({});
  };

  return (
    <div className={classes.otherActivities}>
      <h2>Other Activities Information</h2>
      <Space>
        <Button onClick={handleResetAll}>Reset all</Button>
      </Space>
      <TableWrapper>
        <Table
          bordered
          scroll={{ x: "calc(800px + 50%)" }}
          pagination={{ pageSize: 15 }}
          columns={columns}
          dataSource={tableData}
          onChange={handleChange}
        />
      </TableWrapper>
      <EditModal
        isEditModalOpen={isEditModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        orderDetails={editOrder}
        handleFieldChange={handleFieldChange}
      />
    </div>
  );
};

export default OtherActivities;
