import { CloudinaryImageT } from "../../../../types/common-types";
import { OurTeamProps } from "../../../../types/component-types/skiSchoolPageProps";
import React, { useState } from "react";
import useMessages from "../../../../hooks/useMessages";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { messageResT } from "../../../../api/responses";
import { updateTeamSection } from "../../../../api/services/skiSchoolServices";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";
import { MediaDataT } from "../../../../types/mediaType";
import { Button, Flex, Input } from "antd";
import MediaCard from "../../../../components/MediaCard/MediaCard";
import MediaCardsWrapper from "../../../../components/MediaCardsWrapper/MediaCardsWrapper";
import MediaEditModal from "../../../../components/MediaEditModal/MediaEditModal";
import BannerResolutionWarning from "src/components/BannerResolutionWarning/BannerResolutionWarning";

const OurTeam: React.FC<OurTeamProps> = ({ data, getData }) => {
  const { title, items } = data;
  const [titleValue, setTitleValue] = useState<string>(title);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemValues, setItemValues] = useState<MediaDataT>();

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleEditModalOpen = (itemId: string) => {
    const item = items.find((item) => item._id === itemId);
    setItemValues(item);
    setIsEditModalOpen(true);
  };

  const onTitleUpdateConfirm = () => {
    try {
      setLoading(true);
      makeRequest(
        updateTeamSection({
          sectionTitle: titleValue,
        }),
        (e) => {
          success(e.message);
          setIsEditTitle(false);
          getData();
          setLoading(false);
        }
      );
    } catch (e) {
      error("Something went wrong");
    }
  };

  const handleFieldChange = async (
    fieldName: string,
    value: string | CloudinaryImageT
  ) => {
    setLoading(true);
    let image: any;
    if (fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setItemValues(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  const handleOkEdit = () => {
    try {
      setLoading(true);
      if (itemValues) {
        makeRequest(
          updateTeamSection(
            { ...itemValues, itemTitle: itemValues.title },
            itemValues._id
          ),
          (e) => {
            success(e.message);
            setIsEditModalOpen(false);
            getData();
            setLoading(false);
          }
        );
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>OUR SKI SCHOOL TEAM Section</h2>
          <Flex align="center" gap={8}>
            <Flex align="center" gap={8}>
              <p>Title:</p>
              {isEditTitle ? (
                <Input
                  type="text"
                  value={titleValue}
                  onChange={(e) => setTitleValue(e.target.value)}
                />
              ) : (
                <h4>{title}</h4>
              )}
            </Flex>

            <Flex align="center" gap={12}>
              <Button onClick={() => setIsEditTitle(!isEditTitle)}>
                {isEditTitle ? "Cancel" : "Edit"}
              </Button>
              {isEditTitle && (
                <Button
                  type="primary"
                  onClick={onTitleUpdateConfirm}
                  loading={loading}
                >
                  Confirm
                </Button>
              )}
            </Flex>
          </Flex>

          <BannerResolutionWarning width="236" height="236" />

          <MediaCardsWrapper>
            {items.map((item) => (
              <MediaCard
                key={item._id}
                data={item}
                onEdit={() => handleEditModalOpen(item._id)}
              />
            ))}
          </MediaCardsWrapper>

          <MediaEditModal
            isMediaEditModalOpen={isEditModalOpen}
            handleOk={handleOkEdit}
            handleCancel={() => setIsEditModalOpen(false)}
            handleFieldChange={handleFieldChange}
            mediaDetails={itemValues}
            isLoading={loading}
          />
        </Flex>
      </section>
    </>
  );
};

export default OurTeam;
