import { useState } from "react";
import { Button, Flex, Input, Modal } from "antd";
import MediaCardsWrapper from "../../../MediaCardsWrapper/MediaCardsWrapper";
import ImageCard from "../../../ImageCard/ImageCard";
import { MainSectionProps } from "../../../../types/component-types/otherActivitiesProps";
import useMessages from "../../../../hooks/useMessages";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { messageResT } from "../../../../api/responses";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";
import { updateMainSection } from "../../../../api/services/otherActivitiesServices";
import { MultipleImagesT } from "../../../../types/common-types";
import classes from "./mainActivitiesSection.module.css";
import BannerResolutionWarning from "src/components/BannerResolutionWarning/BannerResolutionWarning";

const MainActivitiesSection: React.FC<MainSectionProps> = ({
  data,
  getData,
}) => {
  const { title, subtitle, images } = data;

  const [prevImage, setPrevImage] = useState<string | null>(null);
  const [editImageDetail, setEditImageDetail] =
    useState<MultipleImagesT | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [isEditingSubtitle, setIsEditingSubtitle] = useState<boolean>(false);
  const [editedTitle, setEditedTitle] = useState<string>(title);
  const [editedSubtitle, setEditedSubtitle] = useState<string>(subtitle);
  const [loading, setLoading] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();
  const [, makeRequest] = useApiRequest<messageResT>();

  const showEditModal = (id: string) => {
    const editableItem = images.find((item) => item?._id === id);
    if (editableItem) {
      setEditImageDetail(editableItem);
      setPrevImage(editableItem.url);
      setIsEditModalOpen(true);
    }
  };

  const handleImageChange = async (file: File) => {
    try {
      setLoading(true);
      const uploadedImage = await cloudinaryUpload(file);
      if (uploadedImage) {
        const imageDetail = {
          public_id: uploadedImage.public_id,
          url: uploadedImage.url,
        };
        if (isEditModalOpen && editImageDetail) {
          setEditImageDetail({ ...editImageDetail, ...imageDetail });
        }
      }
      setLoading(false);
    } catch (err) {
      error("Image upload failed");
    }
  };

  const handleEditSave = () => {
    setLoading(true);
    const updateData = {
      title: editedTitle,
      subtitle: editedSubtitle,
      image: editImageDetail
        ? {
            public_id: editImageDetail.public_id,
            url: editImageDetail.url,
          }
        : undefined,
    };

    makeRequest(
      updateMainSection(updateData, editImageDetail?._id),
      (response) => {
        success(response.message);
        getData();
        setIsEditModalOpen(false);
      }
    );
    setLoading(false);
  };

  const toggleTitleEdit = () => {
    if (isEditingTitle) {
      setEditedTitle(title);
    }
    setIsEditingTitle(!isEditingTitle);
  };

  const onTitleUpdateConfirm = () => {
    setIsEditingTitle(false);
    handleEditSave();
  };

  const toggleSubtitleEdit = () => {
    if (isEditingSubtitle) {
      setEditedSubtitle(subtitle);
    }
    setIsEditingSubtitle(!isEditingSubtitle);
  };

  const onSubtitleUpdateConfirm = () => {
    setIsEditingSubtitle(false);
    handleEditSave();
  };
  return (
    <>
      {contextHolder}

      <section>
        <Flex vertical gap={12}>
          <h2>Main section</h2>
          <Flex gap={12}>
            <Flex align="center" gap={8}>
              <p>Title:</p>
              {isEditingTitle ? (
                <Input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                />
              ) : (
                <h4>{title}</h4>
              )}
            </Flex>

            <Flex align="center" gap={12}>
              <Button onClick={toggleTitleEdit}>
                {isEditingTitle ? "Cancel" : "Edit"}
              </Button>
              {isEditingTitle && (
                <Button
                  type="primary"
                  onClick={onTitleUpdateConfirm}
                  loading={loading}
                >
                  Confirm
                </Button>
              )}
            </Flex>
          </Flex>

          <Flex gap={12}>
            <Flex align="flex-start" gap={8} className={classes.fullWidth}>
              <p>Subtitle:</p>
              {isEditingSubtitle ? (
                <Input.TextArea
                  value={editedSubtitle}
                  onChange={(e) => setEditedSubtitle(e.target.value)}
                />
              ) : (
                <h4>{subtitle}</h4>
              )}
            </Flex>

            <Flex align="center" gap={12}>
              <Button onClick={toggleSubtitleEdit}>
                {isEditingSubtitle ? "Cancel" : "Edit"}
              </Button>
              {isEditingSubtitle && (
                <Button
                  type="primary"
                  onClick={onSubtitleUpdateConfirm}
                  loading={loading}
                >
                  Confirm
                </Button>
              )}
            </Flex>
          </Flex>

          <BannerResolutionWarning width="488" height="340" />

          <MediaCardsWrapper>
            {images.map((item) =>
              item ? (
                <ImageCard
                  key={item._id}
                  data={item}
                  onEdit={() => showEditModal(item._id)}
                />
              ) : null
            )}
          </MediaCardsWrapper>
        </Flex>

        <Modal
          title="Edit Image"
          open={isEditModalOpen}
          onOk={handleEditSave}
          onCancel={() => setIsEditModalOpen(false)}
          className={classes.editModal}
          okButtonProps={{ loading }}
          centered
        >
          {prevImage && (
            <img className={classes.prevImg} src={prevImage} alt="Preview" />
          )}
          <input
            type="file"
            onChange={(e) =>
              e.target.files && handleImageChange(e.target.files[0])
            }
          />
        </Modal>
      </section>
    </>
  );
};

export default MainActivitiesSection;
