import { Button, Flex, Input } from "antd";
import React, { useState } from "react";
import { messageResT, TransferReqT } from "src/api";
import { updatePriceManagementTransfer } from "src/api/services/priceManagementServices";
import { useApiRequest } from "src/hooks/useApiRequests";
import useMessages from "src/hooks/useMessages";
import { TransferPricesUpdateComponentProps } from "src/types";

const TransfersPrices: React.FC<TransferPricesUpdateComponentProps> = ({
  getData,
  _id,
  fullDayTourTbilisiToKazbegi,
  gudauriToGergeti,
  gudauriToKazbegi,
  gudauriToKhada,
  gudauriToTbilisi,
  gudauriToTbilisiAirport,
  tbilisiAirportToGudauri,
  tbilisiFreedomSquareToGudauri,
  tbilisiToKazbegi,
}) => {
  const [editValues, setEditValues] = useState<TransferReqT>({
    _id,
    fullDayTourTbilisiToKazbegi,
    gudauriToGergeti,
    gudauriToKazbegi,
    gudauriToKhada,
    gudauriToTbilisi,
    gudauriToTbilisiAirport,
    tbilisiAirportToGudauri,
    tbilisiFreedomSquareToGudauri,
    tbilisiToKazbegi,
  });

  const [isEdit, setIsEdit] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateConfirm = () => {
    try {
      makeRequest(
        updatePriceManagementTransfer({ ...editValues }, editValues._id),
        (e) => {
          success(e.message);
          getData();
          setLoading(false);
          setIsEdit([]);
        }
      );
    } catch (e) {
      error("Something went wrong");
    } finally {
      setLoading(false);
      setIsEdit([]);
    }
  };

  return (
    <>
      {contextHolder}
      <Flex gap={24} vertical align="flex-start">
        <Flex gap={24} vertical>
          <h2>Transfer And Tours</h2>

          <Flex gap={12} align="center">
            <h3>Gudauri To Tbilisi</h3>

            {isEdit.includes("gudauriToTbilisi") ? (
              <Input
                value={editValues.gudauriToTbilisi}
                onChange={handleChange}
                name="gudauriToTbilisi"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{gudauriToTbilisi}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("gudauriToTbilisi")) {
                      return prev.filter((e) => e !== "gudauriToTbilisi");
                    }
                    return [...prev, "gudauriToTbilisi"];
                  })
                }
              >
                {isEdit.includes("gudauriToTbilisi") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Gudauri To Tbilisi Airport</h3>

            {isEdit.includes("gudauriToTbilisiAirport") ? (
              <Input
                value={editValues.gudauriToTbilisiAirport}
                onChange={handleChange}
                name="gudauriToTbilisiAirport"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{gudauriToTbilisiAirport}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("gudauriToTbilisiAirport")) {
                      return prev.filter(
                        (e) => e !== "gudauriToTbilisiAirport"
                      );
                    }
                    return [...prev, "gudauriToTbilisiAirport"];
                  })
                }
              >
                {isEdit.includes("gudauriToTbilisiAirport") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Tbilisi Airport To Gudauri</h3>

            {isEdit.includes("tbilisiAirportToGudauri") ? (
              <Input
                value={editValues.tbilisiAirportToGudauri}
                onChange={handleChange}
                name="tbilisiAirportToGudauri"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{tbilisiAirportToGudauri}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("tbilisiAirportToGudauri")) {
                      return prev.filter(
                        (e) => e !== "tbilisiAirportToGudauri"
                      );
                    }
                    return [...prev, "tbilisiAirportToGudauri"];
                  })
                }
              >
                {isEdit.includes("tbilisiAirportToGudauri") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Tbilisi Freedom Square To Gudauri</h3>

            {isEdit.includes("tbilisiFreedomSquareToGudauri") ? (
              <Input
                value={editValues.tbilisiFreedomSquareToGudauri}
                onChange={handleChange}
                name="tbilisiFreedomSquareToGudauri"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{tbilisiFreedomSquareToGudauri}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("tbilisiFreedomSquareToGudauri")) {
                      return prev.filter(
                        (e) => e !== "tbilisiFreedomSquareToGudauri"
                      );
                    }
                    return [...prev, "tbilisiFreedomSquareToGudauri"];
                  })
                }
              >
                {isEdit.includes("tbilisiFreedomSquareToGudauri")
                  ? "Cancel"
                  : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Gudauri To Kazbegi</h3>

            {isEdit.includes("gudauriToKazbegi") ? (
              <Input
                value={editValues.gudauriToKazbegi}
                onChange={handleChange}
                name="gudauriToKazbegi"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{gudauriToKazbegi}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("gudauriToKazbegi")) {
                      return prev.filter((e) => e !== "gudauriToKazbegi");
                    }
                    return [...prev, "gudauriToKazbegi"];
                  })
                }
              >
                {isEdit.includes("gudauriToKazbegi") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Gudauri To Gergeti</h3>

            {isEdit.includes("gudauriToGergeti") ? (
              <Input
                value={editValues.gudauriToGergeti}
                onChange={handleChange}
                name="gudauriToGergeti"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{gudauriToGergeti}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("gudauriToGergeti")) {
                      return prev.filter((e) => e !== "gudauriToGergeti");
                    }
                    return [...prev, "gudauriToGergeti"];
                  })
                }
              >
                {isEdit.includes("gudauriToGergeti") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Gudauri To Khada</h3>

            {isEdit.includes("gudauriToKhada") ? (
              <Input
                value={editValues.gudauriToKhada}
                onChange={handleChange}
                name="gudauriToKhada"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{gudauriToKhada}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("gudauriToKhada")) {
                      return prev.filter((e) => e !== "gudauriToKhada");
                    }
                    return [...prev, "gudauriToKhada"];
                  })
                }
              >
                {isEdit.includes("gudauriToKhada") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Tbilisi To Kazbegi</h3>

            {isEdit.includes("tbilisiToKazbegi") ? (
              <Input
                value={editValues.tbilisiToKazbegi}
                onChange={handleChange}
                name="tbilisiToKazbegi"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{tbilisiToKazbegi}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("tbilisiToKazbegi")) {
                      return prev.filter((e) => e !== "tbilisiToKazbegi");
                    }
                    return [...prev, "tbilisiToKazbegi"];
                  })
                }
              >
                {isEdit.includes("tbilisiToKazbegi") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>Full Day Tour Tbilisi To Kazbegi</h3>

            {isEdit.includes("fullDayTourTbilisiToKazbegi") ? (
              <Input
                value={editValues.fullDayTourTbilisiToKazbegi}
                onChange={handleChange}
                name="fullDayTourTbilisiToKazbegi"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{fullDayTourTbilisiToKazbegi}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("fullDayTourTbilisiToKazbegi")) {
                      return prev.filter(
                        (e) => e !== "fullDayTourTbilisiToKazbegi"
                      );
                    }
                    return [...prev, "fullDayTourTbilisiToKazbegi"];
                  })
                }
              >
                {isEdit.includes("fullDayTourTbilisiToKazbegi")
                  ? "Cancel"
                  : "Edit"}
              </Button>
            </Flex>
          </Flex>
        </Flex>

        {isEdit.length !== 0 && (
          <Button
            type="primary"
            style={{ maxWidth: 137 }}
            onClick={handleUpdateConfirm}
            loading={loading}
          >
            Confirm Updates
          </Button>
        )}
      </Flex>
    </>
  );
};

export default TransfersPrices;
