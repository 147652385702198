import { Alert } from "antd";

const BannerResolutionWarning = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => {
  return (
    <Alert
      message="Warning: Invalid Image Resolution on Banner"
      description={`Please upload an image with a resolution of ${width}x${height} pixels only. Images with different resolutions may not display correctly.`}
      type="warning"
      showIcon
    />
  );
};

export default BannerResolutionWarning;
